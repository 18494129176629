import { title } from "@core/utils/filter";

const leadFields = [
  {
    key: "date_even",
    label: "",
    sortable: false,
  },
  {
    key: "lead_name",
    label: "Name",
    formatter: title,
    sortable: false,
  },
  {
    key: "states_eeuu_slug",
    label: "State",
    sortable: false,
  },
  { key: "status", sortable: false },
  { key: "mobile", sortable: false },
  {
    key: "credit_report",
    label: "CR",
    sortable: false,
  },
  {
    key: "source_name",
    label: "Source",
    sortable: false,
  },
  { key: "programs", sortable: false },
  // { key: "type_doc", sortable: false },
  {
    key: "created_by",
    label: "Created By",
    sortable: false,
  },
  // {
  //   key: "assign_to",
  //   label: "Assign To",
  //   sortable: false,
  // },
  // {
  //   key: "client_ceo",
  //   label: "client of ceo",
  //   sortable: false,
  // },
  { key: "actions" },
];

const leadSnFields = [
  { key: "nickname", sortable: false },
  { key: "mobile", sortable: false },
  { key: "status", sortable: false },
  {
    key: "status_recovery",
    label: "Type",
    sortable: false,
  },
  { key: "fanpage", sortable: false },
  {
    key: "programs",
    label: "Recomendations",
    sortable: false,
  },
  { key: "appointment", sortable: false },
  {
    key: "created_date",
    label: "Created Date",
    sortable: false,
  },
  { key: "owner", sortable: false },
  {
    key: "client_ceo",
    label: "client of ceo",
    sortable: false,
  },
];

const leadWPotentials = [
  { key: "nickname", sortable: false },
  { key: "mobile", sortable: false },
  { key: "status", sortable: false },
  {
    key: "status_recovery",
    label: "Type",
    sortable: false,
  },
  { key: "fanpage", sortable: false },
  {
    key: "programs",
    label: "Recomendations",
    sortable: false,
  },
  { key: "appointment", sortable: false },
  {
    key: "created_date",
    label: "Created Date",
    sortable: false,
  },
  { key: "owner", sortable: false },
  { key: "actions", thClass: "text-center" },
  {
    key: "client_ceo",
    label: "client of ceo",
    sortable: false,
  },
];

export default {
  leadFields,
  leadSnFields,
  leadWPotentials,
};

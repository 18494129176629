export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Owner',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'ST/AD',
    model: null,
    options: [],
    reduce: 'label',
    selectText: 'label',
    cols: 12,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'SOURCE',
    model: null,
    options: [
      {
        id: 0,
        name: "ALL",
      },
      {
        id: 1,
        name: "Facebook",
      },
      {
        id: 2,
        name: "Google",
      },
    ],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
  }, // 4
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'SUB SOURCE',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: false
  },  // 5
]
